import React from "react";
import Cookies from "js-cookie";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { BoxArrowRight } from 'react-bootstrap-icons';
import "./App.css";

const NavBar = ({ isAuthenticated, setIsAuthenticated }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    setIsAuthenticated(false);
    Cookies.remove("authenticationData");
    navigate("/Home");
    // Additional logout logic (e.g., clearing tokens) can be added here
  };
  return (
    <Navbar bg="dark" data-bs-theme="dark">
      <Container>
        <Nav className="me-auto">
          <Navbar.Brand as={Link} to="/Home">
            {!isAuthenticated && `Home`}{" "}
            {isAuthenticated &&
              `${JSON.parse(Cookies.get("authenticationData")).FirstName} ${
                JSON.parse(Cookies.get("authenticationData")).LastName
              }`}
          </Navbar.Brand>
          {!isAuthenticated && <br /> && (
            <Nav.Link as={Link} to="/SignIn">
              Sign In
            </Nav.Link>
          )}
          {!isAuthenticated && <br /> && (
            <Nav.Link as={Link} to="/Signup">
              Register
            </Nav.Link>
          )}
          {isAuthenticated && <br /> && (
            <Nav.Link as={Link} to="/reservation">
              Reservation
            </Nav.Link>
          )}
          {isAuthenticated && <br /> && (
            <Nav.Link as={Link} to="/MyReservation">
              My Reservation
            </Nav.Link>
          )}
        </Nav>
        {isAuthenticated && (
          <Nav.Link className="ml-auto" onClick={handleLogout}>
            <BoxArrowRight color="white" />
          </Nav.Link>
        )}
      </Container>
    </Navbar>
  );
};

export default NavBar;
