import React from "react";
import hamoudate from "./Images/ris.png"; // Assurez-vous que l'image est bien dans ce chemin
import "./Home.css"; // Importer le fichier CSS
import "./App.css";

export default function Home() {
  return (
    <div className="full-screen">
      <img src={hamoudate} className="full-image" alt="description" />
    </div>
  );
}
