import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Home from "./Home";
import Reservation from "./Reservation";
import MyReservation from "./MyReservation";
import NavBar from "./Navbar";
import React, { useState, useEffect } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import PrivateRoute from "./PrivateRoute";
import Cookies from "js-cookie";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authData = Cookies.get("authenticationData");
    if (authData) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <NavBar
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Navigate to="/Home" />} />
          <Route path="/Home" element={<Home />} />
          <Route
            path="/SignIn"
            element={
              isAuthenticated ? (
                <Navigate to="/Home" />
              ) : (
                <SignIn setIsAuthenticated={setIsAuthenticated} />
              )
            }
          />
          <Route
            path="/SignUp"
            element={
              isAuthenticated ? (
                <Navigate to="/Home" />
              ) : (
                <SignUp setIsAuthenticated={setIsAuthenticated} />
              )
            }
          />
          <Route
            path="/Reservation"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Reservation />
              </PrivateRoute>
            }
          />
          <Route
            path="/MyReservation"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <MyReservation />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
