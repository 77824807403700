import React from "react";
import { Navigate, useLocation } from "react-router-dom";

function PrivateRoute({ children, isAuthenticated }) {
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/SignIn" state={{ from: location }} />;
  }

  return children;
}

export default PrivateRoute;